<template>
    <div class="bonus-add">
      <div class="bonus-add__body">
        <h2>{{ title }}</h2>
        <div class="suggested-amounts">
          <img v-if="selectedAmount" class="bonus-add__icon" src="@/assets/icons/orderbonus-active.png" />
          <img v-if="!selectedAmount" class="bonus-add__icon" src="@/assets/icons/orderbonus-inactive.png" />

      <button
        v-for="amount in suggestedAmounts"
        :key="amount"
        :class="['amount-button', { active: amount === selectedAmount }]"
        @click="selectAmount(amount)"
      > 
        + $ {{ amount }}  
      </button> 
      </div>
      <div v-if="!selectedAmount" class="bonus-text" style="color:#A6A6A6;">
        Choose priority bonus 
      </div> 
      <div v-if="selectedAmount" class="bonus-text">
        Priority bonus selected
      </div> 
      <form-input
          v-model="customAmount"
          placeholder="Custom priority bonus"
          :error="v$.customAmount.$error"
          :errorText="amountError"
          :showPrefix="true"
      ></form-input>
      </div>
      <div class="bonus-add__footer">
        <button class="button button--ghost" @click="$emit('close')">
          Cancel
        </button>
        <button class="button button--primary" @click="submitHandler" :disabled="!isChanged || !isAmountValid || loading" >
          <span v-if="!loading">Send</span>
          <span v-else class="spinner"></span>
        </button>
      </div>
    </div>
  </template>
  
<script>
  import { useVuelidate } from "@vuelidate/core";
  import { required, numeric, maxValue } from "@vuelidate/validators";
  import FormInput from "@/components/form/FormInput.vue";
  
  export default {
    components: {
      FormInput,
    },

    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  
    data() {
      return {
        form: {
            order_id: this.data.orderId || null,
            amount: this.data.bonusAmount||null,
            title: "",
            description: "",
            type: 2
        },
        suggestedAmounts: [5, 10, 15, 20],
        selectedAmount: null,
        customAmount: null,
        loading: false, 
      };
    },
    setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),
    validations() {
      return {
        customAmount: { numeric, maxValue: maxValue(499.999999999) },
      };
    },
    computed: {
      title() {
      return this.data.bonusAmount ? 'Edit Bonus' : 'Add Bonus';
      },
      isChanged() {
        return Number(this.form.amount) !== Number(this.data.bonusAmount);
      },
      amountError() {
      //   if (this.v$.customAmount.$error) {
      //     return this.v$.customAmount.$errors.some(e => e.$validator === "maxValue")
      //       ? "Amount must be less than 500."
      //       : "Please enter a valid number.";
      //   }
      // return null;
      if (this.customAmount === 500) {
          console.log("clickrd");
          return "Amount must be less than 500.";
        }

      if (this.v$.customAmount.$error) {

        const errors = this.v$.customAmount.$errors;
      if (errors.some(e => e.$validator === "numeric")) {
        return "Please enter a valid number.";
      }
      if (errors.some(e => e.$validator === "maxValue")) {
        return "Amount must be less than 500.";
      }
      return "Please enter a valid number.";
    } 
      return null;
    },
    
    isAmountValid() {
      return this.form.amount && this.form.amount > 0 && this.form.amount < 500;
  },
    },
  
    methods: {
      async submitHandler() {
        this.v$.$touch();
        if (this.v$.$invalid || !this.isAmountValid) {
          return;
        }
        this.loading = true;
        try {
          this.form.amount = this.customAmount ? Number(this.customAmount) : this.selectedAmount;

          await this.data.callback({
            closeModal: () => this.$emit("close"),
            form: { ...this.form, amount: Number((Number(this.form.amount).toFixed(2))* 100 ).toFixed(0)},
          });
        } catch (error) {
          console.error("API call failed", error);
        } finally {
          this.loading = false;
        }
      },

      selectAmount(amount) {
        this.selectedAmount = amount;
        this.form.amount = amount; 
        this.customAmount = null;
      },    
    },

    watch: {
      customAmount(newVal) {
        if (newVal !== null && newVal !== '') {
          this.selectedAmount = null;
          this.form.amount = Number(newVal);
        }
      },

      "data.bonusAmount": {
        handler(newBonusAmount) {
          if (newBonusAmount) {
            this.customAmount = newBonusAmount;
            this.selectedAmount = null;
            this.form.amount = newBonusAmount;
          }
        },
        immediate: true, 
  }
    },
  
  };
</script>
  
  <style lang="scss" scoped>
  .bonus-add {
    &__body {
      padding: 40px 40px 24px 40px;
      border-bottom: 1px solid #f5f5f5;
      color: #37352f;
  
      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 40px;
      }
    }

    &__icon{
          width: 22px;
          height: 25px;
          margin: 9px 2px;
    }
  
    &__form {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      &-denomination {
        :deep(.input-field__inner) {
          border: 1.5px solid #ebebea;
          border-radius: 12px;
          padding: 6.5px 20px;
  
          &--error {
            border-color: #da615c;
          }
          input {
            margin: 0;
          }
        }
      }
    }
  
    &__footer {
      padding: 20px 28px 20px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      button {
        width: 156px;
      }
    }
    .suggested-amounts {
      display: flex;
      gap: 10px;
      margin-bottom: 16px;
      justify-content: center;
    }

    .amount-button {
      padding: 10px 15px;
      border-radius: 8px;
      background: #A6A6A6;
      cursor: pointer;
      transition: 0.1s;
      border: none;
      color: white;
      font-size: 16px;
      font-family: Raleway;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      opacity: 40%;
    }

    .amount-button.active {
      background: #B97FBC;
      color: white;
      opacity: 100%;
    }

    .bonus-text{
      margin: 10px 10px 10px 10px; 
      font-size: 13px; 
      font-weight: 600; 
      line-height: 18px;
      text-align: center;
    }
    .amount-button:hover {
      background: #B97FBC;
      color: white;
      opacity: 100%;
    }

  }
  .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #f3f3f3; 
    border-top: 2px solid #3498db; 
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    display: inline-block;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  </style>
  