<template>
  <div class="input-field">
    <div
      class="input-field__inner"
      :class="{
        'input-field__inner--disabled': disabled,
        'input-field__inner--error': error,
      }"
    >
      <label for="">{{ label }}</label>
      <div v-if="showPrefix" style="display: flex;">
        <div v-if="modelValue"  class="input-field__prefix"
        :class="{ 'input-field__prefix--active': isActive }">+$</div>
        <input
          :disabled="disabled"
          :value="modelValue"
          @keypress="checkInputFilters"
          @input="(e) => $emit('update:modelValue', e.target.value)"
          @focus="$emit('focus')"
          :type="type"
          :placeholder="placeholder"
        />
      </div>
      
      <input
        v-if="!showPrefix"
        :disabled="disabled"
        :value="modelValue"
        @keypress="checkInputFilters"
        @input="(e) => $emit('update:modelValue', e.target.value)"
        @focus="$emit('focus')"
        :type="type"
        :placeholder="placeholder"
      />
      <img
        v-if="false"
        class="input-field__check"
        src="@/assets/icons/fill-done.svg"
      />
    </div>
    <span v-if="error && errorText" class="input-field__error-text">{{
      errorText
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Enter Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
    type: {
      type: String,
      default: "text",
    },
    regexFilter: {
      type: RegExp,
      required: false,
    },
    showPrefix: {
      type: Boolean,
      default: false, 
    },
  },

  methods: {
    checkInputFilters(event) {
      if (this.regexFilter) {
        if (!this.regexFilter.test(event.key)) return event.preventDefault();
      }
      if(this.showPrefix){
        const currentValue = event.target.value;
        const newValue = currentValue + event.key;
        if (!/^\d*\.?\d{0,2}$/.test(newValue)) {
          event.preventDefault();
        }
      }
    },
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.isActive = e.target.value.length > 0; 
    },
    setActive(status) {
      this.isActive = status || !!this.modelValue;
    },
  },
};
</script>

<style scoped lang="scss">
.input-field {
  &__inner {
    border: 1.5px solid #ebebea;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 6.5px 20px;
    position: relative;

    &--error {
      border-color: #da615c;
    }

    &--disabled {
      background: #f5f5f5;
    }
  }
  &__prefix {
    margin-right: 2px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter, sans-serif;
    color: #37352f;
  }

  label {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  input {
    font-family: Inter, sans-serif;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  &__check {
    position: absolute;
    top: 50%;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__error-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #da615c;
    margin-top: 6px;
    display: block;
  }
}
</style>
